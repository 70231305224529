import React from 'react';

const AccountsCountWidget = ({ count }) => {
  return (
    <div style={{ backgroundColor: '#2196f3', color: 'white', padding: '10px', borderRadius: '5px', textAlign: 'center', marginBottom: '20px' }}>
      <h2>Total Accounts: {count}</h2>
    </div>
  );
};

export default AccountsCountWidget;