import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LeadsCountWidget from './LeadsCountWidget';
import ContactsCountWidget from './ContactsCountWidget';
import AccountsCountWidget from './AccountsCountWidget';

const apiUrlLeads = 'https://crm.yastechsolution.com/api/leads_api.php';
const apiUrlContacts = 'https://crm.yastechsolution.com/api/contacts_api.php';
const apiUrlAccounts = 'https://crm.yastechsolution.com/api/accounts_api.php';

function Dashboard() {
  const [leadsCount, setLeadsCount] = useState(0);
  const [contactsCount, setContactsCount] = useState(0);
  const [accountsCount, setAccountsCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const leadsResponse = await fetch(apiUrlLeads);
        const leadsData = await leadsResponse.json();
        setLeadsCount(leadsData.length);

        const contactsResponse = await fetch(apiUrlContacts);
        const contactsData = await contactsResponse.json();
        setContactsCount(contactsData.length);

        const accountsResponse = await fetch(apiUrlAccounts);
        const accountsData = await accountsResponse.json();
        setAccountsCount(accountsData.length);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  const handleWidgetClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Typography variant="h2" component="h1" align="center" gutterBottom>
        YOHO CRM
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 2 }} onClick={() => handleWidgetClick('/leads')} style={{ cursor: 'pointer' }}>
            <LeadsCountWidget count={leadsCount} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 2 }} onClick={() => handleWidgetClick('/contacts')} style={{ cursor: 'pointer' }}>
            <ContactsCountWidget count={contactsCount} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{ p: 2 }} onClick={() => handleWidgetClick('/accounts')} style={{ cursor: 'pointer' }}>
            <AccountsCountWidget count={accountsCount} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;