import React from 'react';

const ContactsCountWidget = ({ count }) => {
  return (
    <div style={{ backgroundColor: '#4caf50', color: 'white', padding: '10px', borderRadius: '5px', textAlign: 'center', marginBottom: '20px' }}>
      <h2>Total Contacts: {count}</h2>
    </div>
  );
};

export default ContactsCountWidget;
