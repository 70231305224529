import React from 'react';

const Reports = () => {
  return (
    <div style={{ backgroundColor: '#ff6347', color: 'white', padding: '10px', borderRadius: '5px', textAlign: 'center', marginBottom: '20px' }}>
    <h2>Reports </h2>
  </div>
  );
};

export default Reports;
