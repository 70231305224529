import React, { useState, useEffect } from 'react';

const LeadsCountWidget = ({ count }) => {
  return (
    <div style={{ backgroundColor: '#ff6347', color: 'white', padding: '10px', borderRadius: '5px', textAlign: 'center', marginBottom: '20px' }}>
      <h2>Total Leads: {count}</h2>
    </div>
  );
};

const LeadsForm = () => {
  const [formData, setFormData] = useState({
    leadName: '',
    company: '',
    email: '',
    phone: '',
    leadSource: '',
    leadOwner: '',
    firstName: '',
    lastName: '',
    title: '',
    fax: '',
    mobile: '',
    website: '',
    leadStatus: '',
    industry: '',
    employees: '',
    annualRevenue: '',
    rating: '',
    description: '',
    emailOptOut: false,
  });

  const [leads, setLeads] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const apiUrl = 'https://crm.yastechsolution.com/api/leads_api.php';

  // Fetch all leads
  const fetchLeads = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setLeads(data);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = editingId ? 'PUT' : 'POST';
      const body = editingId ? { ...formData, id: editingId } : formData;

      const response = await fetch(apiUrl, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      console.log(data);

      if (method === 'POST') {
        alert('Lead created successfully');
      } else {
        alert('Lead updated successfully');
      }

      fetchLeads(); // Refresh leads list
      setFormData({
        leadName: '',
        company: '',
        email: '',
        phone: '',
        leadSource: '',
        leadOwner: '',
        firstName: '',
        lastName: '',
        title: '',
        fax: '',
        mobile: '',
        website: '',
        leadStatus: '',
        industry: '',
        employees: '',
        annualRevenue: '',
        rating: '',
        description: '',
        emailOptOut: false,
      });
      setEditingId(null);
    } catch (error) {
      console.error('Error saving lead:', error);
    }
  };

  const handleEdit = (lead) => {
    setFormData(lead);
    setEditingId(lead.id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      const data = await response.json();
      console.log(data);
      alert('Lead deleted successfully');
      fetchLeads();
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Lead Management</h1>
      <LeadsCountWidget count={leads.length} />
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <input
          type="text"
          name="leadName"
          placeholder="Lead Name"
          value={formData.leadName}
          onChange={handleChange}
        />
        <input
          type="text"
          name="company"
          placeholder="Company"
          value={formData.company}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleChange}
        />
        {/* Add more fields as needed */}
        <button type="submit">
          {editingId ? 'Update Lead' : 'Add Lead'}
        </button>
      </form>

      <h2>Leads List</h2>
      <table border="1" cellPadding="10" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Lead Name</th>
            <th>Company</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id}>
              <td>{lead.id}</td>
              <td>{lead.leadName}</td>
              <td>{lead.company}</td>
              <td>{lead.email}</td>
              <td>{lead.phone}</td>
              <td>
                <button onClick={() => handleEdit(lead)}>Edit</button>
                <button onClick={() => handleDelete(lead.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeadsForm;