import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Dashboard from './components/Dashboard';
import LeadsForm from './components/LeadsForm';
import ContactForm from './components/ContactForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AccountsForm from './components/AccountsForm';
import Reports from './components/Reports';
import Settings from './components/Settings';






function App() {
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ flexGrow: 1, padding: '16px' }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/leads" element={<LeadsForm />} />
            <Route path="/contacts" element={<ContactForm />} />
            <Route path="/accounts" element={<AccountsForm />} />
            <Route path="/reports" element={<Reports/>} />
            <Route path="/settings" element={<Settings/>} />

            <Route path="*" element={<Box>Not Found</Box>} />

          </Routes>
        </div>
      </div>

    </Router>
  );
}

export default App;


