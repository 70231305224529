import React, { useState, useEffect } from 'react';

const AccountsCountWidget = ({ count }) => {
  return (
    <div style={{ backgroundColor: '#2196f3', color: 'white', padding: '10px', borderRadius: '5px', textAlign: 'center', marginBottom: '20px' }}>
      <h2>Total Accounts: {count}</h2>
    </div>
  );
};

const AccountsForm = () => {
  const [formData, setFormData] = useState({
    account_name: '',
    industry: '',
    address: '',
    phone_number: '',
    website: '',
  });

  const [accounts, setAccounts] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const apiUrl = 'https://crm.yastechsolution.com/api/accounts_api.php'; // Replace with your API endpoint

  // Fetch all accounts
  const fetchAccounts = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setAccounts(data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = editingId ? 'PUT' : 'POST';
      const body = editingId ? { ...formData, id: editingId } : formData;

      const response = await fetch(apiUrl, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      console.log(data);

      if (method === 'POST') {
        alert('Account created successfully');
      } else {
        alert('Account updated successfully');
      }

      fetchAccounts(); // Refresh accounts list
      setFormData({
        account_name: '',
        industry: '',
        address: '',
        phone_number: '',
        website: '',
      });
      setEditingId(null);
    } catch (error) {
      console.error('Error saving account:', error);
    }
  };

  const handleEdit = (account) => {
    setFormData(account);
    setEditingId(account.id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      const data = await response.json();
      console.log(data);
      alert('Account deleted successfully');
      fetchAccounts();
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Account Management</h1>
      <AccountsCountWidget count={accounts.length} />
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <input
          type="text"
          name="account_name"
          placeholder="Account Name"
          value={formData.account_name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="industry"
          placeholder="Industry"
          value={formData.industry}
          onChange={handleChange}
        />
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
        />
        <input
          type="text"
          name="phone_number"
          placeholder="Phone Number"
          value={formData.phone_number}
          onChange={handleChange}
        />
        <input
          type="url"
          name="website"
          placeholder="Website"
          value={formData.website}
          onChange={handleChange}
        />
        <button type="submit">
          {editingId ? 'Update Account' : 'Add Account'}
        </button>
      </form>

      <h2>Accounts List</h2>
      <table border="1" cellPadding="10" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Account Name</th>
            <th>Industry</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Website</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <tr key={account.id}>
              <td>{account.id}</td>
              <td>{account.account_name}</td>
              <td>{account.industry}</td>
              <td>{account.address}</td>
              <td>{account.phone_number}</td>
              <td>
                <a href={account.website} target="_blank" rel="noopener noreferrer">
                  {account.website}
                </a>
              </td>
              <td>{new Date(account.created_at).toLocaleString()}</td>
              <td>
                <button onClick={() => handleEdit(account)}>Edit</button>
                <button onClick={() => handleDelete(account.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountsForm;