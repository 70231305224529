import React, { useState, useEffect } from 'react';

const ContactsCountWidget = ({ count }) => {
  return (
    <div style={{ backgroundColor: '#4caf50', color: 'white', padding: '10px', borderRadius: '5px', textAlign: 'center', marginBottom: '20px' }}>
      <h2>Total Contacts: {count}</h2>
    </div>
  );
};

const ContactsForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    company_name: '',
    zoom_id: '',
  });

  const [contacts, setContacts] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const apiUrl = 'https://crm.yastechsolution.com/api/contacts_api.php'; // Replace with your API endpoint

  // Fetch all contacts
  const fetchContacts = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const method = editingId ? 'PUT' : 'POST';
      const body = editingId ? { ...formData, id: editingId } : formData;

      const response = await fetch(apiUrl, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      console.log(data);

      if (method === 'POST') {
        alert('Contact created successfully');
      } else {
        alert('Contact updated successfully');
      }

      fetchContacts(); // Refresh contacts list
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        company_name: '',
        zoom_id: '',
      });
      setEditingId(null);
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  const handleEdit = (contact) => {
    setFormData(contact);
    setEditingId(contact.id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      const data = await response.json();
      console.log(data);
      alert('Contact deleted successfully');
      fetchContacts();
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Contact Management</h1>
      <ContactsCountWidget count={contacts.length} />
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={formData.first_name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={formData.last_name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone_number"
          placeholder="Phone Number"
          value={formData.phone_number}
          onChange={handleChange}
        />
        <input
          type="text"
          name="company_name"
          placeholder="Company Name"
          value={formData.company_name}
          onChange={handleChange}
        />
        <input
          type="text"
          name="zoom_id"
          placeholder="Zoom ID"
          value={formData.zoom_id}
          onChange={handleChange}
        />
        <button type="submit">
          {editingId ? 'Update Contact' : 'Add Contact'}
        </button>
      </form>

      <h2>Contacts List</h2>
      <table border="1" cellPadding="10" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Company Name</th>
            <th>Zoom ID</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact.id}>
              <td>{contact.id}</td>
              <td>{contact.first_name}</td>
              <td>{contact.last_name}</td>
              <td>{contact.email}</td>
              <td>{contact.phone_number}</td>
              <td>{contact.company_name}</td>
              <td>{contact.zoom_id}</td>
              <td>{new Date(contact.created_at).toLocaleString()}</td>
              <td>
                <button onClick={() => handleEdit(contact)}>Edit</button>
                <button onClick={() => handleDelete(contact.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactsForm;